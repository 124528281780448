<template>
  <b-modal
    id="modal-modify-ancillary-other-select-pax"
    header-bg-variant="light-info"
    :title="$t('reservation.addOtherServices')"
    title-class="text-airline fw-700 font-medium-4 m-50"
    header-class="p-50"
    body-class="p-1"
    footer-class="p-50"
    no-close-on-backdrop
    no-enforce-focus
    centered
    size="lg"
    @show="showThisModal"
  >
    <template #modal-footer="{close}">
      <div class="w-100 d-flex-center">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-secondary mr-1"
          pill
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="info"
          class="px-2 d-flex-center"
          pill
          :disabled="loadingNextButton"
          @click="openModalOtherSecondHandle"
        >
          <div class="d-flex-center ml-25">
            <BSpinner
              v-if="loadingNextButton"
              small
              class="mx-2"
            />
            <span v-else>Tiếp tục</span>
          </div>
        </b-button>
      </div>
    </template>

    <div>
      <p class="fw-700 text-medium-1">
        Chọn hành khách để mua thêm gói dịch vụ cho từng hành trình:
      </p>
      <BCard
        v-for="({segment, arrPax, addedAncillaryTrip}, index) of dataList"
        :key="index"
        header-bg-variant="light-warning"
        header-class="fw-700 p-50 px-lg-1 mb-50"
        body-class="px-50 px-sm-1"
        class="border-warning"
      >
        <template #header>
          <div class="mr-25">
            <span class="pr-25 text-body">
              {{ `Hành trình: ${segment.departure.iataCode}-${segment.arrival.iataCode}` }}
            </span>
            <span class="text-airline">
              ( {{ `${convertISODateTime(segment.departure.at, segment.departure.timeZone).dayAndMonth}` }} )
            </span>
            <span v-if="segment.status === 'CKIN'">{{ segment.status }}</span>
          </div>
          <div class="my-25 mr-sm-2">
            <b-form-checkbox
              :id="`trip-select-${index + 1}`"
              class="text-airline fw-700 font-medium-1"
              :disabled="disableSelectedAll(arrPax, addedAncillaryTrip) || segment.status === 'CKIN'"
              :checked="isCheckedAll(arrPax, addedAncillaryTrip)"
              @change="toggleSelectAll(arrPax, addedAncillaryTrip)"
            >
              {{ isCheckedAll(arrPax, addedAncillaryTrip) ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
            </b-form-checkbox>
          </div>
        </template>

        <div>
          <!-- status trong segment là CKIN thì ko cho mua hành lý, seat (https://discord.com/channels/1054696448110903327/1299932651280662528/1300367492027518996) -->
          <BAlert
            v-if="segment.status === 'CKIN'"
            show
            variant="danger"
            class="text-danger px-2 py-50 fw-700 my-50"
          >
            Vé đã check-in không thể thêm chỗ ngồi
          </BAlert>
          <div
            v-for="(pax, indexPax) of dataPaxList"
            :key="indexPax"
            class="d-flex my-50 my-lg-75"
          >
            <b-form-checkbox
              :id="`pax-select-${index + 1}_${indexPax + 1}`"
              :checked="isCheckItem(arrPax, pax)"
              :disabled="disableSelectItem(arrPax, pax, addedAncillaryTrip) || segment.status === 'CKIN'"
              @change="handleChooseItem(arrPax, pax)"
            >
              <span class="fw-700 pl-md-1">{{ pax.lastName }} {{ pax.firstName }}</span>

              <BBadge
                v-if="addedAncillaryTrip.some(i => i.paxId === pax.paxId)"
                variant="warning"
                class="mx-25 px-75 rounded-lg"
              >
                {{ ['TH'].includes(bookingSource) ? 'Đã có dịch vụ' : 'Đã có gói' }}
              </BBadge>
            </b-form-checkbox>
          </div>
        </div>
      </BCard>
    </div>

    <ModalOtherSecondHandle :data-list-to-second="dataListToSecond" />
  </b-modal>
</template>

<script>
import {
  BCard,
  BButton,
  BModal,
  BBadge,
  BFormCheckbox,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import {
  computed, ref,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'

import { resolveSsrNameByCode } from '@/constants/selectOptions'

import { convertISODateTime } from '@core/utils/filter'

import useReservation from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

import useToast from '@useToast'

export default {
  components: {
    BButton,
    BModal,
    BBadge,
    BCard,
    BFormCheckbox,
    BSpinner,
    BAlert,

    ModalOtherSecondHandle: () => import('./modal-second-select-other.vue'),
  },
  setup() {
    const { toastWarning } = useToast()

    const {
      getBookingData,
    } = useReservation()

    const {
      fnAncillary,
      bookingSource,
      delay,
    } = useAncillaryServicesHandle()

    const dataList = ref(null)

    const dataPaxList = computed(() => getBookingData.value?.paxLists?.filter(p => !['INFANT'].includes(p.paxType)))
    const listSelectedAll = getBookingData.value.itineraries.map(() => false)
    const dataListToSecond = ref(null)
    const loadingNextButton = ref(false)

    // ============================== showThisModal
    function showThisModal() {
      dataList.value = getBookingData.value.itineraries.map((trip, indexTrip) => trip.map((segment, indexSegment) => ({
        segment,
        indexTrip,
        source: getBookingData.value.source,
        indexSegment,
        TRIP_FLIGHT: `${trip[0].departure.iataCode}-${trip[trip.length - 1].arrival.iataCode}`,
        addedAncillaryTrip: getBookingData.value.ancillaryServices.filter(it => !['BAGGAGE', 'SEAT'].includes(it.serviceType) && !isEmpty(it.segmentIds) && it.segmentIds.some(aSegment => trip.some(s => (['QH'].includes(bookingSource.value) ? s.devAddonsSegmentIndex : s.segmentId) === aSegment))),
        arrPax: [],
        newAddItems: [],
      }))).flat(2)

      dataListToSecond.value = null
    }

    async function openModalOtherSecondHandle() {
      const filterData = dataList.value.filter(item => !isEmpty(item.arrPax))
      if (isEmpty(filterData)) {
        toastWarning({
          title: 'Chưa chọn hành khách!',
        })
        return
      }

      dataListToSecond.value = filterData.map(i => {
        const addonsOfPaxSelectedInTrip = i.addedAncillaryTrip.filter(a => i.arrPax.some(p => p.paxId === a.paxId))

        return {
          ...i,
          flights: `${i.segment.departure.iataCode}-${i.segment.arrival.iataCode}`,
          ...(['QH', 'VN1A', 'VN1A_MT'].includes(bookingSource.value) && {
            addedAncillaryTrip: addonsOfPaxSelectedInTrip,
            arrPax: i.arrPax.map(p => ({ ...p, addonsOfPax: addonsOfPaxSelectedInTrip.filter(a => a.paxId === p.paxId) })),
          }),
        }
      })

      loadingNextButton.value = true

      try {
        await delay(300)
        this.$bvModal.show('modal-modify-second-add-other')
      } catch (error) {
        console.error('Error openModalOtherSecond::', error)
      } finally {
        await delay(200)
        loadingNextButton.value = false
      }
    }

    // ============================== CheckItem
    function isCheckItem(arrPax, pax) {
      return arrPax.some(p => p.paxId === pax.paxId)
    }
    function disableSelectItem(arrPax, pax, addedAncillaryTrip) {
      if (isEmpty(addedAncillaryTrip) || isEmpty(arrPax)) return false
      // NOTE QH: "Đã chọn pax có addons => disable pax còn lại", "Đã chọn pax chưa có addons => disable những pax đã có addons"
      if (['QH'].includes(bookingSource.value)) {
        const paxHasAddonsInArr = arrPax.some(p => addedAncillaryTrip.some(i => i.paxId === p.paxId))
        const thisPaxHasAddons = addedAncillaryTrip.some(i => i.paxId === pax.paxId)
        const result = paxHasAddonsInArr ? (arrPax[0].paxId !== pax.paxId) : thisPaxHasAddons
        return result
      }
      if (['TH'].includes(bookingSource.value)) {
        return addedAncillaryTrip.filter(i => i.serviceType === 'OTHERS').some(i => i.paxId === pax.paxId)
      }
      return false
    }
    function handleChooseItem(arrPax, pax) {
      const index = arrPax.findIndex(p => p.paxId === pax.paxId)
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? arrPax.splice(index, 1) : arrPax.push(pax)
    }

    // ==================== CheckAll
    function disableSelectedAll(arrPax, addedAncillaryTripParams) {
      if (isEmpty(addedAncillaryTripParams)) return false

      if (['TH'].includes(bookingSource.value)) {
        const addedAncillaryTrip = addedAncillaryTripParams.filter(i => i.serviceType === 'OTHERS')
        const isSelectPaxHasAddons = arrPax.some(p => addedAncillaryTrip.some(i => i.paxId === p.paxId))
        if (isSelectPaxHasAddons) return true
        return dataPaxList.value.every(pax => addedAncillaryTrip.some(i => i.paxId === pax.paxId))
      }

      if (['QH'].includes(bookingSource.value)) {
        const isSelectPaxHasAddons = arrPax.some(p => addedAncillaryTripParams.some(i => i.paxId === p.paxId))
        if (isSelectPaxHasAddons) return true
        return dataPaxList.value.every(pax => addedAncillaryTripParams.some(i => i.paxId === pax.paxId))
      }

      return false
    }

    function allPaxChecked(arrPax, addedAncillaryTripParams) {
      let listPax = dataPaxList.value
      if (['TH'].includes(bookingSource.value)) {
        listPax = dataPaxList.value.filter(pax => !disableSelectItem(arrPax, pax, getBookingData.value.ancillaryServices))
      }
      if (['QH'].includes(bookingSource.value)) {
        listPax = dataPaxList.value.filter(p => !addedAncillaryTripParams.some(i => i.paxId === p.paxId))
      }

      const a = (arrPax.length === listPax.length)
      const b = arrPax.every(pax => listPax.some(p => p.paxId === pax.paxId))
      return { listPax, checkAll: a && b }
    }
    function isCheckedAll(arrPax, addedAncillaryTripParams) {
      if (isEmpty(arrPax)) return false
      const { checkAll } = allPaxChecked(arrPax, addedAncillaryTripParams)
      return checkAll
    }
    function toggleSelectAll(arrPax, addedAncillaryTripParams) {
      const { listPax, checkAll } = allPaxChecked(arrPax, addedAncillaryTripParams)
      if (checkAll) {
        arrPax.splice(0, arrPax.length)
      } else {
        const result = listPax.filter(pax => !arrPax.some(p => p.paxId === pax.paxId))
        arrPax.push(...result)
      }
    }

    return {
      dataList,
      dataPaxList,
      listSelectedAll,
      isEmpty,
      dataListToSecond,
      loadingNextButton,
      fnAncillary,
      bookingSource,
      getBookingData,

      toggleSelectAll,
      disableSelectedAll,
      isCheckedAll,

      isCheckItem,
      handleChooseItem,
      disableSelectItem,

      convertISODateTime,
      resolveSsrNameByCode,

      showThisModal,
      openModalOtherSecondHandle,
    }
  },
}
</script>
